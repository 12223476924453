import HttpService from '../core/rest/http.service';

export async function createOrder(post) {
    const { data } = await HttpService.post('/v1/order', post);
    return data.data;
}
export async function createPickUp(post) {
    const { data } = await HttpService.post('/v1/order/pick-up', post);
    return data.data;
}
export async function loadDhlProductForOrder(orderId) {
    const { data } = await HttpService.get('/v1/order/' + orderId + '/product');
    return data.data;
}
