import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import './core/polyfills';
import {formatNumber} from '@/core/helper/number.helper';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.filter('numberFormat', function (value) {
    if (!value) return ''
    return formatNumber(value);
});

Vue.filter('currencySymbol', function (value) {
    return value + ' Kč';
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
