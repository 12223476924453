import moment from 'moment';

export function calculateDeliveryDate(transportDays) {

    function addWorkDays(startDate, days) {
        if (isNaN(days)) {
            return null;
        }
        if (!(startDate instanceof Date)) {
            return null;
        }
        let dow = startDate.getDay();
        let daysToAdd = parseInt(days);
        if (dow == 0) {
            daysToAdd++;
        }
        if (dow + daysToAdd >= 6) {
            let remainingWorkDays = daysToAdd - (5 - dow);
            daysToAdd += 2;
            if (remainingWorkDays > 5) {
                daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
                if (remainingWorkDays % 5 == 0) {
                    daysToAdd -= 2;
                }
            }
        }
        startDate.setDate(startDate.getDate() + daysToAdd);
        return startDate;
    }

    return moment(addWorkDays(new Date(), transportDays));
}
