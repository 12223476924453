
export default {
    required:  'Položka je povinná',
    decimal:   'Je povoleno pouze číslo',
    numeric:   'Položka musí být celé číslo',
    maxLength: 'Položka může obsahovat maximálně %p znaků',
    minLength: 'Položka může obsahovat minimálně %p znaků',
    maxValue:  'Nejvyšší hodnota může být %p',
    minValue:  'Nejnižší hodnota může být %p',
    priceLimitValidator:  'Maximální pojistná hodnota je ve výši 1.000.000,- Kč, pokud chcete poslat hodnotnější zásilku, kontaktujte nás na info@svetovykuryr.cz',
    decimalWithComma:  'Je povoleno pouze číslo',
}

export function errorParameter(error) {
    let parameter = null;
    const { type } = error;
    switch (type) {
        case 'maxLength':
            parameter = error.max;
            break;
        case 'minLength':
            parameter = error.min;
            break;
        case 'maxValue':
            parameter = error.max;
            break;
        case 'minValue':
            parameter = error.min;
            break;
    }

    return parameter;
}
