<template>
    <div>
        <label class="e-checkbox" v-if="label">
            <span class="e-checkbox__label">{{label}}</span>
            <input type="checkbox" :checked="value" :name="name" @change="onChange" :disabled="disabled">
            <span class="e-checkbox__tick"></span>
        </label>
        <input-error v-if="v" :v="v" :name="name" @sk-error="error = $event"></input-error>
    </div>
</template>

<script>
    import InputError from './InputError';

    export default {
        name:       'FormCheckbox',
        components: { InputError },
        props:      {
            v:        {
                required: false,
            },
            name:     {
                type:     String,
                required: true,
            },
            value:    {
                required: true,
                default:  () => null,
            },
            disabled: {
                type:    Boolean,
                default: () => false,
            },
            label:    {
                type:     String,
                required: false,
            }
        },
        data() {
            return {
                error:   false,
                focused: false,
                checked: this.value,
            }
        },
        methods:    {
            onChange($event) {
                this.$emit('input', $event.target.checked);
            }
        }
    }
</script>
