<template>
    <div v-bind:class="{'error': error, 'activeLabel': focused || value}">
        <input :type="type" ref="input" :value.trim="value" :name="name" @input="onUpdate()" @blur="onBlur" @focus="onFocus" :disabled="disabled"/>
        <label class="e-label" v-if="label">{{label}}</label>
        <input-error :v="v" :name="name" @sk-error="error = $event"></input-error>
    </div>
</template>

<script>

    import InputError from './InputError';

    export default {
        name:       'FormInput',
        components: { InputError },
        props:      {
            v:        {
                required: true,
            },
            name:     {
                type:     String,
                required: true,
            },
            value:    {
                required: true,
                default:  () => null,
            },
            type:     {
                type:    String,
                default: () => 'text',
            },
            disabled: {
                type:    Boolean,
                default: () => false,
            },
            label:    {
                type:     String,
                required: false,
            },
        },
        data() {
            return {
                error:   false,
                focused: false,
            }
        },
        methods:    {
            onUpdate() {
                const value = this.$refs.input.value;
                this.$emit('input', value);
            },
            onBlur() {
                this.focused = false;
                this.$emit('blur');
            },
            onFocus() {
                this.focused = true;
                this.$emit('focus');
            },
        }
    }
</script>
