<template>
    <div class="e-error">
        <div class="e-message e-message--error" v-if="v.$dirty" v-for="error in errors">{{error}}</div>
    </div>
</template>

<script>
    import errorMessages, {errorParameter} from '../../../core/form/messages';
    import map from 'lodash/map';

    export default {
        name:     'InputError',
        props:    {
            v:    {
                requierd: true,
            },
            name: {
                type:     String,
                requierd: true,
            }
        },
        computed: {
            errors() {
              const errors = [];
              let objectKey = null;
              let name = this.name;
              if (name.indexOf('.') !== -1) {
                  let objectSplit = name.split('.');
                  objectKey = objectSplit[0];
                  name = objectSplit[1];
              }
              const validatorObject = objectKey ? this.v[objectKey] : this.v;
              if (validatorObject.hasOwnProperty(name) && this.v.$dirty) {
                  const validateObject = validatorObject[name];
                  for (let key in validateObject) {
                      if (key.indexOf('$') === -1) {
                          if (validateObject[key] === false) {
                              let parameters = {};
                              let parameter = null;
                              if (validateObject.hasOwnProperty('$params')) {
                                  parameters = validateObject.$params;
                              }
                              if (parameters.hasOwnProperty(key) && parameters[key]) {
                                  parameter = errorParameter(parameters[key]);
                              }
                              errors.push({type: key, parameter});
                          }
                      }
                  }
              }
              this.$emit('sk-error', errors.length ? true : false);
              return map(errors, ({type, parameter}) => {
                  if (errorMessages.hasOwnProperty(type)) {
                      if (parameter) return errorMessages[type].replace('%p', parameter);
                      return errorMessages[type];
                  }
                  return type;
              });
            }
        },
        methods:  {}
    }
</script>
