<template>
    <div>
        <span class="label">Vaše zásilka</span>
        <div :style="{'paddingBottom': '1rem'}">
          <form-delivery-type-select ref="deliveryTypeSelect" v-if="deliveryTypes.length"/>
        </div>
        <div :key="index" v-for="(v, index) in $v.packages.$each.$iter">
            <div v-bind:class="{'f-calculator-packages--error': v.error.$model}" class="f-calculator-packages">
                <div class="f-calculator-packages__left">
                    <span class="f-calculator-packages__first">
                        <span class="f-calculator-packages__label dimensions">Rozměry</span>
                    </span>
                    <span class="f-calculator-packages__second">
                        <form-input v-model.trim="v.width.$model" :v="v" name="width" @blur="onBlur"/>
                        <span class="f-calculator-packages__cross"></span>
                        <form-input v-model.trim="v.height.$model" :v="v" name="height" @blur="onBlur"/>
                        <span class="f-calculator-packages__cross"></span>
                        <form-input v-model.trim="v.depth.$model" :v="v" name="depth" @blur="onBlur"/>
                        <span class="f-calculator-packages__label cm">cm</span>
                    </span>
                </div>

                <div class="f-calculator-packages__right">
                    <span class="f-calculator-packages__first">
                        <span class="f-calculator-packages__label weight">Hmotnost</span>
                    </span>
                    <span class="f-calculator-packages__second">
                        <form-input v-model.trim="v.weight.$model" :v="v" name="weight" @blur="onBlur"/>
                        <span class="f-calculator-packages__label kg">kg</span>

                        <button class="e-button e-button--trash f-calculator-packages__trash" v-if="packages.length > 1" type="button" @click="removeCurrentPackage(index)">
                            <i class="e-icon e-icon--trash"></i>
                        </button>
                        <button class="e-button e-button--add f-calculator-packages__add" @click="addNewPackage">
                            <i class="e-icon e-icon--plus"></i>
                            Přidat
                        </button>
                    </span>
                </div>
                <div class="e-message e-message--error" v-if="v.error.$model">
                    Tuto zásilku není možné přijmout
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {required, numeric, maxValue, minValue} from 'vuelidate/lib/validators'
import {mapMultiRowFields} from 'vuex-map-fields';
import FormInput from '../Form/Input/FormInput';
import {decimalWithComma} from "@/core/form/validator";
import FormDeliveryTypeSelect from "@/components/Form/FormDeliveryTypeSelect.vue";

export default {
    qname:       'CalculatorPackages',
    components:  {FormDeliveryTypeSelect, FormInput },
    validations: {
        packages: {
            required,
            $each: {
                width:  {
                    required,
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(120)
                },
                height: {
                    required,
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(120)
                },
                depth:  {
                    required,
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(160)
                },
                weight: {
                    required,
                    decimalWithComma: decimalWithComma(),
                },
                error:  {}
            }
        }
    },
    computed:    {
        ...mapMultiRowFields('calculator', ['packages']),
        ...mapGetters('common', ['deliveryTypes']),
    },
    methods:     {
        ...mapActions('calculator', ['addPackage', 'removePackage', 'recalculate']),
        addNewPackage() {
            this.addPackage();
            this.$v.$reset();
        },
        removeCurrentPackage(index) {
            this.removePackage(index);
            this.recalculate();
        },
        onBlur() {
            this.recalculate();
        },
        touch() {
            this.$v.$touch();
        },
        validate() {
            return this.$v.$invalid;
        }
    }
}
</script>
