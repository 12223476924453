<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
    import {mapActions} from 'vuex';

    export default {
        beforeMount() {
            this.init();
        },
        methods: {
            ...mapActions('common', ['init']),
        }
    }
</script>
