import parameters from './config';

export default new class {

    constructor() {
        const defaultParameters = {
            apiDomain: null,
        };

        this.config = Object.assign(defaultParameters, parameters);
    }

    get apiDomain() {
        return this.config.apiDomain;
    }
};
