import { helpers } from 'vuelidate/lib/validators'

export function priceLimitValidator(isInsuredRequested) {
    return function (value) {
        if (!isInsuredRequested) {
            return true
        }
        if (!value) {
            return false;
        }

        return isInsuredRequested && parseInt(value) < 1000000
    }
}

export function decimalWithComma() {
    return helpers.regex('decimalWithComma', /^[-]?\d*([.,]\d+)?$/)
}