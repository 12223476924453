import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import AddresseeModule from './addressee.module';
import CustomerModule from './customer.module';
import CalculatorModule from './calculator.module';
import OrderModule from './order.module';
import CommonModule from './common.module';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    strict:  debug,
    modules: {
        addressee:  AddresseeModule,
        customer:   CustomerModule,
        calculator: CalculatorModule,
        order:      OrderModule,
        common:     CommonModule,
    },
    plugins: debug ? [createLogger()] : [],
});

export default store;
