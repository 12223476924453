<template>
    <div>
        <form-select :options="countriesForSelect" v-model="$v.toCountryCode.$model" :disabled="disabled" :v="$v" name="toCountryCode"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { required } from 'vuelidate/lib/validators';
import FormSelect from './Input/FormSelect';

export default {
    name: 'FormCountrySelect',
    components: { FormSelect },
    computed: {
        ...mapFields('calculator', ['toCountryCode']),
        ...mapGetters('common', ['countriesForSelect', 'countryCodes']),
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        }
    },
    validations: {
        toCountryCode: {
            required,
        }
    },
    watch: {
        toCountryCode: function (newValue) {
            const country = this.countriesForSelect.find(({ id }) => id === newValue) || null
            if (country) {
                dataLayer.push({
                    'event': 'formField',
                    'fieldType': 'toCountryCode',
                    'fieldValue': country.name
                });
            }

            this.recalculate();
        }
    },
    mounted() {
        this.recalculate();
        const { selectedCountryCode } = window;
        if (selectedCountryCode && this.countryCodes.indexOf(selectedCountryCode) !== -1) {
            this.toCountryCode = selectedCountryCode;
        }
    },
    methods: {
        ...mapActions('calculator', ['recalculate']),
        touch() {
            this.$v.$touch();
        },
        validate() {
            return this.$v.$invalid;
        }
    }
}
</script>
