<template>
    <div>
        <form @submit.prevent>
            <div class="f-select-country">
                <div class="f-select-country__left">
                    <span class="f-select-country__label">Odkud</span>
                    <div class="select select--fake">
                        Česká republika
                    </div>
                </div>
                <div class="f-select-country__right">
                    <span class="f-select-country__label">Kam</span>
                    <form-country-select ref="countrySelect" v-if="countries.length"/>
                </div>
            </div>

            <calculator-packages ref="calculatorPackages"/>

            <calculator-non-eu-package-price ref="calculatorNonEuPackagePrice"/>

            <div v-if="error">{{ error }}</div>
        </form>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {mapFields} from 'vuex-map-fields';
    import FormCountrySelect from '../Form/FormCountrySelect';
    import CalculatorPackages from './CalculatorPackages';
    import FormInput from "../Form/Input/FormInput";
    import CalculatorNonEuPackagePrice from "./CalculatorNonEuPackagePrice"

    export default {
        name:       'Calculator',
        components: { FormInput, CalculatorPackages, FormCountrySelect, CalculatorNonEuPackagePrice },
        computed:   {
            ...mapFields('calculator', ['toCountryCode']),
            ...mapGetters('calculator', ['error']),
            ...mapGetters('common', ['countries', 'deliveryTypes']),
        },
        methods:    {
            validate() {
                this.$refs.countrySelect.touch();
                //this.$refs.deliveryTypeSelect.touch();
                this.$refs.calculatorPackages.touch();
                this.$refs.calculatorNonEuPackagePrice.touch();
                return false === this.$refs.countrySelect.validate()
                    // && false === this.$refs.deliveryTypeSelect.validate()
                    && false === this.$refs.calculatorPackages.validate()
                    && false === this.$refs.calculatorNonEuPackagePrice.validate();
            },
        }
    }
</script>
