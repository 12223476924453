<template>
    <div>
        <div v-if="selectedPrice" class="e-selected-price">
            Vaše zásilka může být v {{ countryName }} již <span v-if="deliveryDate">{{ deliveryDate.format('DD. MM.') }}</span> za
            <span>{{ (selectedPrice.withVat + insurancePrice) | numberFormat | currencySymbol }}</span>
            s DPH. <template v-if="insurance">Cena je včetně pojištění zásilky.</template>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {calculateDeliveryDate} from "@/core/helper/date.helper";

export default {
    name:     'InfoBar',
    computed: {
        ...mapGetters('calculator', ['selectedPrice', 'transportType', 'toCountryCode', 'insurance', 'insurancePrice']),
        ...mapGetters('common', ['countryByCode']),
        country() {
            return this.countryByCode(this.toCountryCode);
        },
        countryName() {
            if (this.country) return this.country.name;
            return null;
        },
        deliveryDate() {
            if (this.country) {
                const { dayDefinite, timeDefinite } = this.country;
                if (this.transportType === 'express') {
                    return calculateDeliveryDate(timeDefinite);
                } else if (this.transportType === 'economy') {
                    return calculateDeliveryDate(dayDefinite);
                }
                return null;
            }
            ;
            return null;
        }
    },
}
</script>
