import {createOrder, createPickUp, loadDhlProductForOrder} from '@/api/order.api';
import { getRealDeliveryTypeCode } from "@/core/helper/deliveryTypeCode.helper";

export default {
    namespaced: true,
    state:      {
        dhlProduct: null
    },
    getters:    {
        dhlProduct: (state) => state.dhlProduct
    },
    actions:    {
        async init({ commit }, orderId) {
            return loadDhlProductForOrder(orderId)
            .then((dhlProduct) => commit('SET_DHL_PRODUCT', dhlProduct))
        },
        async createOrder({ commit, rootGetters }) {
            const customer = rootGetters['customer/data'];
            const addressee = rootGetters['addressee/data'];
            const packages = rootGetters['calculator/dataPackages'];
            const transportType = rootGetters['calculator/transportType'];
            const toCountryCode = rootGetters['calculator/toCountryCode'];
            const fromCountryCode = rootGetters['calculator/fromCountryCode'];
            const deliveryTypeCode = rootGetters['calculator/deliveryTypeCode'];
            const nonEuPackagePrice = rootGetters['calculator/nonEuPackagePrice'];
            const insurance = rootGetters['calculator/insurance'];
            const insurancePrice = rootGetters['calculator/insurancePrice'];

            customer.countryCode = fromCountryCode;
            addressee.countryCode = toCountryCode;

            const parameters = {
                customer,
                addressee,
                packages,
                transportType,
                deliveryTypeCode: getRealDeliveryTypeCode(deliveryTypeCode),
                nonEuPackagePrice,
                insurancePrice,
                insurance
            };
            return createOrder(parameters)
        },
        async createPickUp({ commit }, data) {
            return createPickUp(data);
        }
    },
    mutations:  {
        SET_DHL_PRODUCT: (state, dhlProduct) => state.dhlProduct = dhlProduct,
    }
}
