import {getField, updateField} from 'vuex-map-fields';
import {clone} from 'lodash';
import {addressFields, companyFields} from './common.module';

export default {
    namespaced: true,
    state:      {
        ...clone(addressFields),
        ...clone(companyFields),
    },
    getters:    {
        getField,
        data: (state) => state,
    },
    actions:    {},
    mutations:  {
        updateField,
    }
}
