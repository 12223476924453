import {loadCountries} from '../api/country.api';
import {loadDeliveryTypes} from '../api/deliveryType.api';

export const addressFields = {
    name:        null,
    email:       null,
    phone:       null,
    address:     null,
    zipCode:     null,
    city:        null,
    addressNote: null
};

export const companyFields = {
    identificationNumber:    null,
    taxIdentificationNumber: null,
    eoriNumber:              null,
};

export default {
    namespaced: true,
    state:      {
        countries: [],
        deliveryTypes: [],
    },
    getters:    {
        countries:          (state) => state.countries,
        countryCodes:       (state, getters) => getters.countries.map(({ code }) => code),
        countriesForSelect: (state, getters) => getters.countries.map(({ code, name }) => {
            return { id: code, name };
        }),
        countryByCode:      (state, getters) => (countryCode) => {
            return getters.countries.find(({ code }) => countryCode === code) || null;
        },

        deliveryTypes:          (state) => state.deliveryTypes,
        typeCodes:      (state, getters) => getters.deliveryTypes.map(({ code }) => code),
        deliveryTypesForSelect: (state, getters) => getters.deliveryTypes.map(({ code, name }) => {
            return { id: code, name };
        })
    },
    actions:    {
        async init({ commit }) {
            return Promise.all([loadDeliveryTypes(), loadCountries()])
            .then((data) => {
                commit('SET_DELIVERY_TYPES', data[0])
                commit('SET_COUNTRIES', data[1])
            })
        }
    },
    mutations:  {
        SET_COUNTRIES: (state, countries) => state.countries = countries,
        SET_DELIVERY_TYPES:     (state, deliveryTypes) => state.deliveryTypes = deliveryTypes,
    }
}
