<template>
    <div>
        <form-checkbox v-model="$v.deliveryTypeCode.$model" :disabled="disabled" :v="$v" name="deliveryTypeCode" label="Posílám jen dokumenty"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { required } from 'vuelidate/lib/validators';
import FormSelect from './Input/FormSelect';
import FormCheckbox from './Input/FormCheckbox';
import { getRealDeliveryTypeCode } from "@/core/helper/deliveryTypeCode.helper";

export default {
    name: 'FormDeliveryTypeSelect',
    components: { FormSelect, FormCheckbox },
    computed: {
        ...mapFields('calculator', ['deliveryTypeCode']),
        ...mapGetters('common', ['deliveryTypesForSelect', 'deliveryTypeCodes']),
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        }
    },
    validations: {
        deliveryTypeCode: {
            required,
        }
    },
    watch: {
        deliveryTypeCode: function (newValue) {
            dataLayer.push({
                'event': 'formField',
                'fieldType': 'deliveryTypeCode',
                'fieldValue': getRealDeliveryTypeCode(newValue)
            });
            this.recalculate();
        }
    },
    mounted() {
        this.recalculate();
        const { selectedTypeCode } = window;
        if (selectedTypeCode && this.typeCodes.indexOf(selectedTypeCode) !== -1) {
            this.deliveryTypeCode = selectedTypeCode;
        }
    },
    methods: {
        ...mapActions('calculator', ['recalculate']),
        touch() {
            this.$v.$touch();
        },
        validate() {
            return this.$v.$invalid;
        }
    }
}
</script>
