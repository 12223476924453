import $axios from './axios';

export default {

    async post(url, body = {}) {
        const headers = await this.getHeaders()
        try {
            const response = await $axios.post(url, body, headers)
            return response;
        } catch (e) {
            return this.parseError(e.response)
        }
    },

    async put(url, body) {
        const headers = await this.getHeaders()
        return await $axios.put(url, body, headers)
        .then((response) => response)
        .catch((e) => this.parseError(e.response));
    },

    async get(url, parameters = {}) {
        const headers = await this.getHeaders()
        return await $axios.get(url + this.prepareParameters(parameters), headers)
        .then((response) => response)
        .catch((e) => this.parseError(e.response));
    },

    async delete(url, parameters = {}) {
        const headers = await this.getHeaders()
        return await $axios.delete(url + this.prepareParameters(parameters), headers)
        .then((response) => response)
        .catch((e) => this.parseError(e.response));
    },

    async getHeaders(json = true) {
        return new Promise((resolve => {
            const headers = {
                'X-Requested-With': 'XMLHttpRequest',
            };
            if (json) {
                headers['Content-Type'] = 'application/json';
            }
            resolve(headers);
        }));
    },

    parseError(response) {
        if (typeof response === 'object' && response.hasOwnProperty('data')) {
            response = response.data;
        }

        if (typeof response === 'object' && response.hasOwnProperty('error')) {
            const { error } = response;
            if (error.hasOwnProperty('message')) {
                throw Error(error.message);
            }

            if (typeof error === 'string' && false === error.startsWith('error.')){
                throw Error(error);
            }
        }
        throw Error('Chyba požadavku');
    },

    prepareParameters(parameters = {}) {
        let result = '';
        if (Object.keys(parameters).length) {
            const buffer = [];
            for (const key in parameters) {
                if (parameters.hasOwnProperty(key)) {
                    if (typeof parameters[key] === 'object') {
                        for (const subKey in parameters[key]) {
                            if (parameters[key].hasOwnProperty(subKey)) {
                                buffer.push(key + '[' + subKey + ']=' + encodeURIComponent(parameters[key][subKey]));
                            }
                        }
                    } else {
                        buffer.push(key + '=' + encodeURIComponent(parameters[key]));
                    }
                }
            }
            result = '?' + buffer.join('&');
        }

        return result;
    }
}
