<template>
  <div v-if="toCountryCode">
    <h3 v-if="shippingOutOfEU">Zásilku posíláte do země mimo EU. Zadejte prosím její hodnotu (v CZK)</h3>
    <div class="input-holder" style="margin-bottom: 0;">
      <form-input v-model.trim="$v.nonEuPackagePrice.$model" :v="$v" name="nonEuPackagePrice" label="Hodnota Vaší zásilky"
                  v-if="shippingOutOfEU || insurance"/>
    </div>
    <br>
    <form-checkbox name="insurance" v-model="$v.insurance.$model" label="Chci zásilku pojistit"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {decimal, requiredIf} from 'vuelidate/lib/validators';
import {mapFields} from 'vuex-map-fields';
import FormInput from '../Form/Input/FormInput';
import FormCheckbox from '../Form/Input/FormCheckbox';
import {priceLimitValidator} from '../../core/form/validator';

export default {
    name:        'NonEuPackagePrice',
    components:  {FormCheckbox, FormInput},
    validations() {
      return {
        nonEuPackagePrice: {
            required: requiredIf('isPriceRequired'),
            decimal,
            priceLimitValidator: priceLimitValidator(this.insurance),
        },
        insurance: {}
      }
    },
    computed:    {
        ...mapFields('calculator', ['nonEuPackagePrice', 'insurance']),
        ...mapGetters('common', ['countryByCode']),
        ...mapGetters('calculator', ['toCountryCode']),
        shippingOutOfEU() {
            return this.toCountryCode && (false === this.countryByCode(this.toCountryCode).inEU)
        },
        isPriceRequired() {
            return this.shippingOutOfEU || this.insurance;
        }
    },
    methods:     {
        onBlur() {
        },
        onFocus() {
        },
        touch() {
            this.$v.$touch();
        },
        validate() {
            return this.$v.$invalid;
        },
        reset() {
            this.$v.$reset();
        },
    }
}
</script>
