import Vue from 'vue';
import {getField, updateField} from 'vuex-map-fields';
import clone from 'lodash/clone';
import {calculatePackagePrice} from '@/api/calculator.api';
import { getRealDeliveryTypeCode } from "@/core/helper/deliveryTypeCode.helper";
import { getWeightAsFloat } from "@/core/helper/weight.helper";

export const packageFields = {width: null, height: null, depth: null, weight: null, error: false};

export default {
    namespaced: true,
    state:      {
        fromCountryCode:   'CZ',
        toCountryCode:     null,
        deliveryTypeCode:  false,
        packages:          [clone(packageFields)],
        nonEuPackagePrice: null,
        insurance:         false,
        economyPrice:      null,
        expressPrice:      null,
        selectedPrice:     null,
        transportType:     null,
        error:             null,
    },
    getters:    {
        getField,
        error:          (state) => state.error,
        transportType:  (state) => state.transportType,
        economyPrice:   (state) => state.economyPrice,
        expressPrice:   (state) => state.expressPrice,
        selectedPrice:  (state) => state.selectedPrice,
        insurancePrice: (state) => {
            if (state.insurance && state.nonEuPackagePrice) {
                const onePercent = state.nonEuPackagePrice / 100;
                return onePercent > 300 ? onePercent : 300;
            } else return 0;
        },

        toCountryCode:     (state) => state.toCountryCode,
        fromCountryCode:   (state) => state.fromCountryCode,
        deliveryTypeCode:  (state) => state.deliveryTypeCode,
        nonEuPackagePrice: (state) => state.nonEuPackagePrice,
        insurance:         (state) => state.insurance,
        packages:          (state) => state.packages,
        filledPackages:    (state) => state.packages.filter(({
                                                                 width,
                                                                 height,
                                                                 depth,
                                                                 weight
                                                             }) => width && height && depth && weight),
        dataPackages:      (state, getters) => {
            const toCountryCode = getters.toCountryCode;
            return getters.packages.map((item) => {
                item.countryCode = toCountryCode;
                return item;
            })
        },
    },
    actions:    {
        addPackage:       ({commit}) => commit('ADD_PACKAGE'),
        removePackage:    ({commit}, index) => commit('REMOVE_PACKAGE', index),
        setTransportType: ({commit}, type) => {
            commit('SET_TRANSPORT_TYPE', type);
            commit('SET_SELECTED_PRICE', type)
        },

        async recalculate({getters, commit}) {
            commit('SET_ERROR', null);
            const countryCode = getters.toCountryCode;
            const deliveryTypeCode = getRealDeliveryTypeCode(getters.deliveryTypeCode);
            if (null === countryCode) return new Promise((resolve) => resolve());
            const packages = getters.filledPackages.map((item) => {
                item.countryCode = countryCode;
                item.deliveryTypeCode = deliveryTypeCode;
                item.weight = getWeightAsFloat(item.weight)
                return item;
            });
            const packageRequests = await packages.map((item) => calculatePackagePrice(item).catch(() => {
                return {economyPrice: null, expressPrice: null};
            }))
            const prices = await Promise.all(packageRequests);

            let economyPrices = {withVat: 0, withoutVat: 0};
            let expressPrices = {withVat: 0, withoutVat: 0};

            prices.map(({economyPrice, expressPrice}, index) => {
                if (economyPrice) {
                    const {withoutVat, withVat} = economyPrice;
                    economyPrices.withVat = economyPrices.withVat + withVat;
                    economyPrices.withoutVat = economyPrices.withoutVat + withoutVat;
                }
                if (expressPrice) {
                    const {withoutVat, withVat} = expressPrice;
                    expressPrices.withVat = expressPrices.withVat + withVat;
                    expressPrices.withoutVat = expressPrices.withoutVat + withoutVat;
                }
                commit('SET_PACKAGE_ERROR', {index, status: (null === economyPrice && null === expressPrice)});
            });

            commit('SET_ECONOMY_PRICES', economyPrices);
            commit('SET_EXPRESS_PRICES', expressPrices);
            commit('SET_SELECTED_PRICE');

            if (economyPrices.withVat === 0 && expressPrices.withVat === 0 && getters.filledPackages.length) {
                commit('SET_ERROR', 'Pro danou kombinaci jsme nenašli cenu');
            }

            return;
        }
    },
    mutations:  {
        updateField,
        SET_ERROR:         (state, error) => state.error = error,
        ADD_PACKAGE:       (state) => state.packages.push(clone(packageFields)),
        REMOVE_PACKAGE:    (state, index) => state.packages.splice(index, 1),
        SET_PACKAGE_ERROR: (state, {index, status}) => {
            const packates = state.packages;
            if (packates.hasOwnProperty(index)) {
                const packageItem = packates[index];
                packageItem.error = status;
                Vue.set(state.packages, index, packageItem);
            }
        },

        SET_TRANSPORT_TYPE: (state, type) => state.transportType = type,

        SET_ECONOMY_PRICES: (state, prices) => {
            const {withVat} = prices;
            state.economyPrice = withVat ? prices : null
        },
        SET_EXPRESS_PRICES: (state, prices) => {
            const {withVat} = prices;
            state.expressPrice = withVat ? prices : null
        },
        SET_SELECTED_PRICE: (state, type = null) => {
            const {economyPrice, expressPrice} = state;
            if (type) {
                if (type === 'economy') state.selectedPrice = economyPrice;
                else if (type === 'express') state.selectedPrice = expressPrice;
                else state.selectedPrice = null;
            } else {
                if (economyPrice && economyPrice.withVat) {
                    state.selectedPrice = economyPrice;
                    state.transportType = 'economy';
                } else if (expressPrice && expressPrice.withVat) {
                    state.selectedPrice = expressPrice;
                    state.transportType = 'express';
                } else {
                    state.selectedPrice = null;
                }
            }
        },
    }
}
