<template>
    <div class="c-order">
        <calculator ref="calculator"/>
        <div class="c-order__footer">
            <div class="c-order__left">
                <info-bar/>
            </div>
            <div class="c-order__right">
                <button class="e-button e-button--main" @click="onContinue">Pokračovat</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Calculator from '../components/Calculator/Calculator';
import InfoBar from '../components/Calculator/InfoBar';

export default {
    components: { InfoBar, Calculator },
    computed: {
        ...mapGetters('calculator', ['selectedPrice']),
    },
    methods: {
        onContinue() {
            if (this.$refs.calculator.validate()) {
                dataLayer.push({
                    'event': 'formButton',
                    'formStep': 'step1'
                });
                this.$router.push({ name: 'Order' });
            }
        }
    }
}
</script>
