<template>
    <div v-bind:class="{'error': error}">
        <label v-if="label">{{label}}</label>
        <select ref="select" :value.trim="value" :name="name" @change="onChange()" @blur="onBlur" @focus="onFocus" :disabled="disabled">
            <option v-if="prompt">{{prompt}}</option>
            <option :key="option.id" :value="option.id" v-for="option in options">{{option.name}}</option>
        </select>
        <input-error :v="v" :name="name" @sk-error="error = $event"></input-error>
    </div>
</template>

<script>
    import InputError from './InputError';

    export default {
        name:       'FormSelect',
        components: { InputError },
        props:      {
            v:        {
                required: true,
            },
            name:     {
                type:     String,
                required: true,
            },
            value:    {
                required: true,
                default:  () => null,
            },
            disabled: {
                type:    Boolean,
                default: () => false,
            },
            label:    {
                type:     String,
                required: false,
            },
            options:  {
                type:     Array,
                required: true,
            },
            prompt:   {
                type:    String,
                default: () => 'Vyberte',
            }
        },
        data() {
            return {
                error:   false,
                focused: false,
            }
        },
        methods:    {
            onChange() {
                const value = this.$refs.select.value;
                this.$emit('input', value);
            },
            onBlur() {
                this.focused = false;
            },
            onFocus() {
                this.focused = true;
            },
        }
    }
</script>
