import Vue from 'vue';
import VueRouter from 'vue-router';
import OrderRouter from './OrderRouter';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path:      '/',
        name:      'Home',
        component: Home,
        children:  [
            {
                path:      ':countryCode',
                component: Home
            }
        ]
    }, {
        path:      '/objednavka',
        component: OrderRouter,
        children:  [
            {
                path:      '',
                name:      'Order',
                component: () => import('../views/Order.vue'),
            },
            {
                path:      'adresat',
                name:      'Addressee',
                component: () => import('../views/Addressee.vue'),
            }, {
                path:      ':orderId/vyzvednuti',
                name:      'PickUp',
                component: () => import('../views/PickUp.vue'),
            }

        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
});

export default router;
